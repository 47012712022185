<template>
  <div class="role">
    <b-row>
      <b-col cols="4">
        <p class="text-header">Role</p>
      </b-col>
      <b-col cols="8">
        <div class="d-flex">
          <div class="g-form">
            <div class="input">
              <b-form-input
                v-model="filter.keyword"
                placeholder="Role"
                @focus="iconSearch = true"
                @blur="iconSearch = false"
                @keyup.enter="searchAdminRole"
              ></b-form-input>
              <b-button
                class="search"
                :class="iconSearch ? 'active-border' : ''"
              >
                <b-icon
                  stacked
                  icon="search"
                  scale="0.5"
                  variant="grey"
                  @click="searchAdminRole"
                ></b-icon>
              </b-button>
            </div>
          </div>

          <!-- <b-button class="btn-filter ml-2" @click="openSideBar">
            <span class="d-none d-md-block">
              <font-awesome-icon icon="filter" class="pointer" />
              <span class="btn-text">Filter</span>
            </span>
          </b-button> -->
          <b-button class="btn-filter ml-2" @click="createRole">
            <span class="d-none d-md-block">
              <span>Create</span>
            </span>
          </b-button>
        </div>
      </b-col>
    </b-row>

    <Table
      :items="items"
      :fields="fields"
      :isBusy="isBusy"
      :rows="rows"
      :filter="filter"
      :pageOptions="pageOptions"
      @filterPage="filterPage"
    />

    <ModalAlertText :textModal="textModal" :arrModal="arrStatus" />
  </div>
</template>

<script>
import Table from "./component/Table.vue";
import ModalAlertText from "@/components/modal/ModalAlertText.vue";
import { required, requiredIf } from "vuelidate/lib/validators";

export default {
  components: {
    Table,
    ModalAlertText,
  },
  data() {
    return {
      // state
      filter: {
        keyword: "",
        skip: 1,
        take: 10,
        page: 1,
      },

      // state table
      items: [],
      fields: [
        {
          key: "id",
          label: "ID",
          class: "w-100px text-nowrap",
        },
        {
          key: "name",
          label: "Name",
          class: "w-100px text-nowrap",
        },
        { key: "actions", label: "Action" },
      ],
      isBusy: false,
      rows: 0,
      pageOptions: [
        { value: 10, text: "10 / page" },
        { value: 30, text: "30 / page" },
        { value: 50, text: "50 / page" },
        { value: 100, text: "100 / page" },
      ],

      // state sidebar
      styleDatetime: {
        width: "100%",
        border: "none",
      },

      // state css
      iconSearch: false,

      // state modal
      textModal: "",
      arrStatus: [],
    };
  },
  mounted() {
    this.searchAdminRole();
    this.openModalAlertText();
  },
  methods: {
    async searchAdminRole() {
      this.isBusy = true;

      const getData = await this.$services.user.searchAdminRole(this.filter);

      if (getData.result == 1) {
        this.items = getData.detail.data;
        this.rows = getData.detail.count;
      }

      this.isBusy = false;
    },
    openModalAlertText() {
      const getText = this.$store.getters.getTextModal;

      if (!getText) {
        return;
      }

      this.textModal = getText;
      this.$bvModal.show("modal-alert-text");
      this.$store.dispatch("UPDATE_TEXTMODAL", "");
    },
    searchAll(filter) {
      let { search } = filter;
      this.filter.keyword = search;
      this.filter.skip = 1;
      this.filter.page = 1;

      this.searchAdminRole();
      this.closeSideBar();
    },
    clearFilter() {
      this.filter = {
        keyword: "",
        skip: 1,
        take: 10,
        page: 1,
      };
      this.searchAdminRole();
    },
    createRole() {
      this.$router.push("/role/details/0");
    },
    filterPage(filter) {
      let { page } = filter;

      this.filter.skip = page;
      this.searchAdminRole();
    },
  },
};
</script>

<style lang="scss" scoped>
.role {
  .text-header {
    color: #092d53;
    font-weight: 600;
    font-size: 20px;
    text-transform: uppercase;
  }
}
.text-error {
  margin: 0;
  color: red;
}
// ! override
.g-form {
  width: 100%;
}

::v-deep .btn-filter button,
.btn-filter {
  color: var(--primary-color) !important;
  border-color: var(--primary-color);
  font-weight: 100;
  min-width: 80px;
}
</style>
