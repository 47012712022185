<template>
  <div>
    <b-row>
      <b-col>
        <b-table
          responsive
          striped
          hover
          :fields="fields"
          :items="items"
          :busy="isBusy"
          show-empty
          empty-text="No matching records found"
        >
          <template v-slot:cell(id)="{ item }">
            <p class="underline m-0" @click="editItem(item)">{{ item.id }}</p>
          </template>
          <template v-slot:cell(actions)="{ item }">
            <font-awesome-icon
              class="pointer"
              icon="pen"
              @click="editItem(item)"
            />
            <!-- <font-awesome-icon
              class="pointer"
              icon="trash-alt"
              @click="removeItem(item)"
            /> -->
          </template>
          <template v-slot:table-busy>
            <div class="text-center text-black my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong class="ml-2">Loading...</strong>
            </div>
          </template>
        </b-table>
      </b-col>
    </b-row>

    <Pagination
      @handleChangeTake="handleChangeTake"
      :pageOptions="pageOptions"
      :rows="rows"
      :filter="filter"
      @pagination="pagination"
    />
  </div>
</template>

<script>
import Pagination from "@/components/Pagination";

export default {
  components: { Pagination },
  props: {
    filter: {
      required: false,
      type: Object,
    },
    pageOptions: {
      required: true,
      type: Array,
    },
    fields: {
      required: true,
      type: Array,
    },
    items: {
      required: true,
      type: Array,
    },
    isBusy: {
      required: true,
      type: Boolean,
    },
    rows: {
      required: true,
      type: Number,
    },
  },
  methods: {
    handleChangeTake(value) {
      this.filter.page = 1;
      this.filter.take = value;
      this.$emit("filterPage", this.filter);
    },
    pagination(page) {
      this.filter.page = page;
      this.$emit("filterPage", this.filter);
    },
    editItem(obj) {
      this.$router.push(`/role/details/${obj.id}`);
    },
    formatNumber(val) {
      return this.$services.utils.formatNumber(val);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep thead {
  background-color: var(--secondary-color);
  color: var(--font-color);
}
.color-red {
  color: #dc3545;
}
.color-green {
  color: #28a745;
}
.color-blue {
  color: lightcoral;
}

.render-payment {
  display: flex;
  justify-content: center;
  align-items: center;
}

.underline {
  text-decoration: underline;
  cursor: pointer;
  display: block;

  // vertical-align: middle;
  // padding-top: 10px;
}
</style>
